<template>
  <div>
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="basic/elevator-maintenance-dict/page">
      <el-button
        v-if="$auth('电梯保养字典新增')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.editPage.open()">
        {{$l("common.add", "新增")}}
      </el-button>

      <el-button
        v-if="$auth('电梯保养字典导入')"
        slot="toolbar"
        :loading="loading"
        type="primary"
        size="mini"
        @click="visible=!visible">
        导入
      </el-button>
      <el-button

        slot="btn"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="exportExcelData">
        {{$l("common.search", "导出")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('user.status','电梯类别')">
            <vm-dict-select v-model.trim="filter.elevatorCategory" type="elevatorCategory"></vm-dict-select>
          </vm-search>
          <vm-search :label="$l('user.name','保养内容')">
            <el-input v-model.trim="filter.content" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('user.status','保养类型')">
            <vm-dict-select v-model.trim="filter.type" type="maintainType"></vm-dict-select>
          </vm-search>
        </div>
      </template>

      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="elevatorCategoryDesc" label="电梯类别" align="center"></el-table-column>
      <el-table-column prop="content" label="保养内容" align="center"></el-table-column>
      <el-table-column prop="requirement" label="保养要求" align="center"></el-table-column>
      <el-table-column prop="typeDesc" label="保养类型" align="center">
        <template slot-scope="scope">
          {{scope.row.types instanceof Array ? scope.row.types.map(item=>{
            if (item.typeDesc){
              return item.typeDesc.replace('[','')

            }else {
              return ''
            }
          }):''}}
        </template>
      </el-table-column>
      <el-table-column prop="needToPhoto" label="是否需要拍照" align="center">
        <template slot-scope="scope">
          {{scope.row.needToPhoto?"是":"否"}}
        </template>
      </el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="130px">
        <template slot-scope="scope">
          <el-button v-if="$auth('电梯保养字典编辑')" type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>
          <el-button v-if="$auth('电梯保养字典删除')" type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <el-dialog :visible.sync="visible" width="450px">
      <vm-drag-upload-file
        url="excelTemplate/maintainDict.xlsx"
        action="basic/elevator-maintenance-dict/import"
        name="保养字典导入"
        download-name="电梯保养字典.xlsx"
        @save-success="getList(-1)"></vm-drag-upload-file>
    </el-dialog>
  </div>
</template>
<script>
  import EditPage from "./MaintenanceEdit";
  import {export2Excel} from "@/util";
  import moment from "moment";

  export default {

    components: {EditPage},
    data() {
      return {
        filter: {
          phone: "",
          name: "",
          type: "",
          status: "",
        },
        visible:false,
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      exportExcelData() {
        const title={
          requirement:"要求",
          content:"内容",
        };
        this.$http.get("basic/elevator-maintenance-dict/page",{...this.filter,pageSize:3000000}).then(res=>{

          export2Excel(title,res.records,`保养规则-${res.total}条-${moment(new Date()).format("YYYYMMDDhhmmss")}`);
        });
      },
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
        this.visible = false;
      },

      toggleStatus(row) {
        let statusDesc = row.status === "enabled" ? "禁用" : "启用";
        this.$confirm(`确定${statusDesc + row.phone}吗?`, "提示", {type: "warning"}).then(() => {
          this.$http.put(`users/${row.id}/toggleStatus`).then(() => {
            this.getList(-1);
          });
        });
      },
      resetPassword(row) {
        this.$prompt("请输入新密码", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          inputPattern: this.$rule.password.pattern,
          inputErrorMessage: "6~20位,不能为纯数字或者纯字母",
        }).then(({value}) => {
          this.$http.put(`users/${row.id}/resetPassword?newPassword=${value}`).then(() => {
            this.$message.success("重置密码成功");
          });
        });
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.name}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete("basic/elevator-maintenance-dict", row.id).then(() => {
            this.getList(-1);
            this.$message.success(row.name + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },

    },
  };
</script>
<style lang="scss" scoped>
</style>
