<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?'编辑保养':'新增保养'"
    width="800px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('device.labelWidth','80px')">
      <el-form-item label="电梯类别" prop="elevatorCategory" :rules="$rule.notNull">
        <vm-dict-select v-model="model.elevatorCategory" type="elevatorCategory"></vm-dict-select>
      </el-form-item>
      <el-form-item label="保养内容" prop="manufacturerName">
        <el-input v-model="model.content"></el-input>
      </el-form-item>
      <el-form-item label="保养要求" prop="manufacturerName">
        <el-input v-model="model.requirement"></el-input>
      </el-form-item>
      <el-form-item label="是否需要拍照" prop="needToPhoto">
        <el-select v-model="model.needToPhoto">
          <el-option label="是" :value="true"></el-option>
          <el-option label="否" :value="false"></el-option>
        </el-select>
      </el-form-item>

      <el-button size="small" type="primary" style="margin-bottom: 10px" @click="onadd">新增</el-button>
      <el-table
        :data="model.regulations"
        label-position="left"
        class="demo-table-expand">
        <el-table-column align="scope.row.center" type="index" width="50"></el-table-column>

        <el-table-column prop="tenantId" label="保养类型" align="center">
          <template slot-scope="scope">
            <vm-dict-select v-model="scope.row.type" type="maintainType"></vm-dict-select>
          </template>
        </el-table-column>

        <el-table-column prop="name" label="保养周期" align="center">
          <template slot-scope="scope">
            <vm-dict-select v-model="scope.row.cycle" type="maintainCycle"></vm-dict-select>
          </template>
        </el-table-column>

        <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="200">
          <template slot-scope="scope">
            <!--          <el-button type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>-->
            <el-button
              type="danger"
              @click="ondel(scope.row)">
              删除
            </el-button>
            <!--          <el-button type="primary" @click="goToDetail(scope.row.id)">{{$l("common.detail", "详情")}}</el-button>-->
            <!--          <el-button type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>-->
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel","取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save","保存")}}</el-button>
    </span>
  </el-dialog>
</template>
<script>


  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          id: 0,
          no: "",
          source: "",
          isp: "",
          iccid: "",
          regulations:[],
        },

      };
      
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`/basic/elevator-maintenance-dict/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.model = data;
            this.model.regulations=data.regulations.map(item=>{
              if (!item.id) {
                item.id=Math.random();
              }

              return item;
            });
          });
        }
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .save("basic/elevator-maintenance-dict", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
      onadd() {
        this.model.regulations.push({id:Math.random()});
      },
      ondel(row) {
        this.model.regulations=this.model.regulations.filter(v=>v.id!==row.id);

      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
